<template>
	<div class="wd-settled">
		<div class="settled-header">
		</div>
		<div class="settled-form">
		<el-form ref="myForm" :label-position="'left'" :rules="rules" label-width="168px" :model="formData" @submit.native.prevent class="submit_info">
			<p class="tipTxt" v-if="tipShow">
				<svg-icon icon-class="ic-lb"></svg-icon>审核结果将以短信形式通知到联系人手机号，请留意查收
				<span class="closeTip"  @click="closeTip">
					<svg-icon icon-class="settled-close"></svg-icon>
				</span>
			</p>
			<el-form-item label="机构名称：" prop="name">
				<el-input v-model="formData.name" size="small" :maxlength="40" placeholder="请输入机构名称"></el-input>
			</el-form-item>

			<el-form-item label="联系人：" prop="userName">
				<el-input v-model="formData.userName" size="small" :maxlength="30" placeholder="请输入联系人姓名"></el-input>
			</el-form-item>
			<el-form-item label="联系电话：" prop="phone" class="hasTip" style="margin-bottom: 8px">
				<el-input v-model.trim="formData.phone" size="small" placeholder="请输入联系电话"></el-input>
			</el-form-item>
			<div class="authCode formItemTip">
				<el-input v-model="authCode" size="small" placeholder="请输入验证码" style="width:calc(100% - 106px)"></el-input>
				<el-button v-if="Countdown" class="getcodeBtn getcodeBtnGray" type="primary" size="small" @click="getAuthCode">{{
					Countdown + 's'
				}}</el-button>
				<el-button v-else-if="isFirstGetAuthcode" class="getcodeBtn" type="primary" size="small" @click="getAuthCode">获取验证码</el-button>
				<el-button v-else class="getcodeBtn" type="primary" size="small" @click="getAuthCode">重新获取</el-button>
			</div>
			<el-form-item label="邮箱:" prop="email" class="hasTip" style="margin-bottom:8px">
                <el-input v-model.trim="formData.email" size="small" placeholder="请输入邮箱"></el-input>
            </el-form-item>
            <div class="authCode formItemTip">
                <el-input v-model="emailAuthCode" size="small" placeholder="请输入验证码" style="width:calc(100% - 106px)"></el-input>
                <el-button v-if="CountdownEmail" class="getcodeBtn getcodeBtnGray" type="primary" size="small" @click="getAuthCodeEamil">{{CountdownEmail+'s'}}</el-button>
                <el-button v-else-if="isFirstGetAuthcodeEmail" class="getcodeBtn" type="primary" size="small" @click="getAuthCodeEamil">获取验证码</el-button>
                <el-button v-else class="getcodeBtn" type="primary" size="small" @click="getAuthCodeEamil">重新获取</el-button>
            </div>
			<el-form-item label="地址：" class="needRequred" style="margin-bottom: 10px">
				<el-row type="flex" justify="space-between">
					<el-col class="selectItem">
						<el-form-item prop="province" style="margin-bottom: 0">
							<el-select v-model="formData.province" size="small" placeholder="请选择省" @change="handleProvinceChange">
								<el-option v-for="item in provinceList" :key="item.code" :label="item.name" :value="item.code"> </el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col class="selectItem">
						<el-form-item prop="city" style="margin-bottom: 0">
							<el-select v-model="formData.city" size="small" placeholder="请选择市" @change="handleCityChange">
								<el-option v-for="item in cityList" :key="item.code" :label="item.name" :value="item.code"> </el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col class="selectItem areaSelect">
						<el-form-item prop="district" style="margin-bottom: 0">
							<el-select v-model="formData.district" size="small" placeholder="请选择区">
								<el-option v-for="item in districtList" :key="item.code" :label="item.name" :value="item.code"> </el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form-item>
			<div class="formItemTip">
				<el-input  v-model="formData.addressDetail" size="small" :maxlength="50" placeholder="请输入详细地址"></el-input>
			</div>
			<el-form-item label="统一社会信用代码：" prop="organCode" key="organCode">
				<el-input v-model="formData.organCode" size="small" placeholder="请输入社会统一信用代码"></el-input>
			</el-form-item>
			<el-form-item label="营业执照副本：" prop="businessLicenseImage">
				<el-upload
					v-loading="licenseImageUploadLoading"
					class="avatar-uploader"
					:class="{ border: formData.businessLicenseImage }"
					:action="action"
					:headers="headers"
					accept="image/jpg, image/jpeg, image/png"
					:show-file-list="false"
					:on-success="licenseImageSuccess"
					:before-upload="beforeLicenseImageUpload"
					:on-error="
						() => {
							licenseImageUploadLoading = false
						}
					"
				>
					<img v-if="formData.businessLicenseImage" :src="formData.businessLicenseImage" class="avatar" />
					<div v-else>
						<i class="el-icon-plus avatar-uploader-icon"></i>
						<p class="uploadTip">点击上传</p>
					</div>
					<div slot="tip" class="el-upload__tip" style="margin-top: -7px">营业执照扫描图片后上传，支持jpg、png格式；图片大小不超过5M。</div>
				</el-upload>
			</el-form-item>

			<!-- 公共实训基地不展示 -->
			<el-form-item v-if="formData.enterType != 7" label="是否允许加入人才库：" prop="isJoinTalent">
				<div class="isJoinTalent">
					<el-switch v-model="formData.isJoinTalent" active-color="#316fff" inactive-color="#EEEEEE" :active-value="1" :inactive-value="0">
					</el-switch>
					<span
						>是否允许机构下的学员加入人才库，加入后平台可依据用户学习结果、测评结果等行为分析，利用大数据手段为招聘企业、应聘学员进行适岗匹配推荐</span
					>
				</div>
			</el-form-item>

			<el-form-item v-if="formData.enterType === 2 || formData.enterType === 7" label="选择培训的职业及工种：" prop="workType">
				<el-select v-model="formData.workType" multiple placeholder="请选择职业及工种" size="small" style="width: 100%">
					<el-option-group v-for="group in options" :key="group.parent_vocation_code" :label="group.vocationName">
						<el-option v-for="item in filterList(group.subVocations)" :key="item.vocationCode" :label="item.vocationName" :value="item.vocationCode"> </el-option>
					</el-option-group>
				</el-select>
			</el-form-item>

			<el-form-item v-if="formData.enterType === 2 || formData.enterType === 7" label="培训教师资格证明：" prop="trainTeacherImage">
				<el-upload
					v-loading="baseImageUploadLoading"
					class="avatar-uploader"
					:action="action"
					:headers="headers"
					accept="image/jpg, image/jpeg, image/png"
					list-type="picture-card"
					:limit="10"
					:on-remove="handleRemove"
					:on-success="baseImageSuccess"
					:before-upload="beforeBaseImageUpload"
					:on-exceed="handleExceed"
					:on-error="
						() => {
							baseImageUploadLoading = false
						}
					"
				>
					<div>
						<i class="el-icon-plus avatar-uploader-icon"></i>
						<p class="uploadTip">点击上传</p>
					</div>
					<div slot="tip" class="el-upload__tip">请上传培训教师资格证明，支持jpg、png格式，图片大小不超过5M。</div>
				</el-upload>
			</el-form-item>
			<el-form-item v-if="formData.enterType === 2 || formData.enterType === 7" label="师资备案表证明：" prop="urlList">
				<div class="zip-box">
					<div class="zip-box-line">
						<div @click.stop="openInput()">
							<upload-zip  
								ref="uploadFileInput"
								:maxSize="1024*1024*50"
								:accept="'.zip,.rar'"
								@file-obj="getFileObj"
								@on-upload-cancel-file="onUploadCanceFile"
								@openInput="openInput()"
								:disabledUpload="fileListFinished.length>0 || fileListZip.length>0"
								@fileloadingdata="fileloadingdata">
							</upload-zip>
						</div>
						<div class="tipBox-teacher">
							<p class="tip">师资备案表以压缩包方式上传，支持zip、rar格式；文件大小不超过50M。<el-button type="text" @click="downloadTeacher">下载师资备案表模板</el-button></p>
						</div>
					</div>
				</div>
				<div class="upload-view"> 
					<ul class="content-accessory" v-if="fileListFinished.length>0">
						<li v-for="(item,index) in fileListFinished" :key="item.id ||index+'key'">
							<div class="accessory-box">
								<div class="accessory-top">
									<div class="top-icon">
										<svg-icon icon-class="zip"></svg-icon>
									</div>
									<p class="top-text">{{item.name}}</p>
								</div>
								<div class='accessory-bottom' >
									<el-button type="button" @click="handleDelResource(item,index,3)">删除</el-button>
								</div>
							</div>
						</li>
					</ul>
					<ul class="content-accessory" v-if="fileListZip.length>0">
						<li v-for="(item,index) in fileListZip" :key="item.id ||index+'key'">
							<div class="accessory-box">
								<div class="accessory-top">
									<div class="top-icon">
										<svg-icon  icon-class="zip"></svg-icon>
									</div>
									<p class="top-text">{{item.name}}</p>
								</div>
								<div class="accessory-bottom" v-if="item.percent<100">
									<el-button type="button" @click="delResource(item, index, 3)">取消上传</el-button>
								</div>
								<el-progress :percentage="Number(item.percent)" :stroke-width="2" :show-text="false" :color="'#1972FF'" v-if="item.percent<100"></el-progress>
							</div>
						</li>
					</ul>
				</div>
			</el-form-item>
			<el-form-item label="班主任信息：" prop="headTeacherVoList">
				<div class="teacherInfo">
					<div class="add" @click="handleAdd">
						<i class="el-icon-circle-plus-outline"></i>
						<span>添加</span>
					</div>
					<div class="tearcherList">
						<div class="teacher" v-for="(item, index) in formData.headTeacherVoList" :key="index">
							<div class="item">
								<div class="left">{{ item.name }}</div>
								<div class="right">
									<span class="edit" @click="handleEditTeacher(item)">编辑</span>
									<span class="close" @click="handleRemoveTeacher(item)"><i class="el-icon-close"></i></span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</el-form-item>
			<el-form-item v-if="formData.enterType === 2 || formData.enterType === 7" label="场地设备信息：" prop="deviceRarList">
				<el-upload
					v-loading="baseImageUploadLoading"
					class="avatar-uploader"
					:action="action"
					:headers="headers"
					accept="image/jpg, image/jpeg, image/png"
					list-type="picture-card"
					:limit="limitDevice"
					:on-remove="handleRemoveDevice"
					:on-exceed="handleExceed2"
					:on-success="baseImageSuccessDevice"
					:before-upload="beforeBaseImageUpload"
					:on-error="
						() => {
							baseImageUploadLoading = false
						}
					"
				>
					<div>
						<i class="el-icon-plus avatar-uploader-icon"></i>
						<p class="uploadTip">上传图片</p>
					</div>
					<div slot="tip" class="el-upload__tip">请上传场地设备信息扫描图片，支持jpg、png格式；最多可上传10个文件。单个图片大小不超过5M。</div>
				</el-upload>
				<div class="zip-box zip-box-mix">
					<div class="zip-box-line">
						<div @click.stop="openInputMix('deviceRarList')">
							<upload-zip-mix  
								:uploadType="'deviceRarList'"
								ref="deviceRarList"
								:maxSize="1024*1024*50"
								:accept="'.zip,.rar'"
								@file-obj="getFileObjMix"
								:clearType="'deviceRarList'"
								:disabledUpload="fileListFinished.length>9 || fileListZip.length>9"
								@on-upload-cancel-file="onUploadCanceFileMix"
								@openInput="openInputMix('deviceRarList')"
								@fileloadingdata="fileloadingdataMix">
							</upload-zip-mix>
						</div>
					</div>
					<p class="tipMix">请上传场地设备信息扫描图片，支持zip、rar格式；最多可上传10个文件。单个压缩文件大小不超过50M。</p>
				</div>
				<div class="upload-view"> 
					<ul class="content-accessory" v-if="previewList.deviceRarList.fileListFinished.length>0">
						<li v-for="(item,index) in previewList.deviceRarList.fileListFinished" :key="item.id ||index+'key'">
							<div class="accessory-box">
								<div class="accessory-top">
									<div class="top-icon">
										<svg-icon icon-class="zip"></svg-icon>
									</div>
									<p class="top-text">{{item.name}}</p>
								</div>
								<div class='accessory-bottom' >
									<el-button type="button" @click="handleDelResourceMix(item,index,'deviceRarList')">删除</el-button>
								</div>
							</div>
						</li>
					</ul>
					<ul class="content-accessory" v-if="previewList.deviceRarList.fileListZip.length>0">
						<li v-for="(item,index) in previewList.deviceRarList.fileListZip" :key="item.id ||index+'key'">
							<div class="accessory-box">
								<div class="accessory-top">
									<div class="top-icon">
										<svg-icon  icon-class="zip"></svg-icon>
									</div>
									<p class="top-text">{{item.name}}</p>
								</div>
								<div class="accessory-bottom" v-if="item.percent<100">
									<el-button type="button" @click="delResourceMix(item, index, 'deviceRarList')">取消上传</el-button>
								</div>
								<el-progress :percentage="Number(item.percent)" :stroke-width="2" :show-text="false" :color="'#1972FF'" v-if="item.percent<100"></el-progress>
							</div>
						</li>
					</ul>
				</div>
			</el-form-item>
			<el-form-item label="承诺函：" prop="letterList" class="letterBox">
				<el-upload
					v-loading="LetterImageUploadLoading"
					class="avatar-uploader"
					:class="{ border: formData.businessLicenseImage }"
					:action="action"
					:headers="headers"
					accept="image/jpg, image/jpeg, image/png"
					:show-file-list="false"
					:on-success="letterImageSuccess"
					:before-upload="beforeLetterImageUpload"
					:on-error="
						() => {
							LetterImageUploadLoading = false
						}
					"
				>
					<div v-if="formData.letterList.length>0" >
						<img :src="formData.letterList[0].url" class="avatar" />
					</div>
					<div v-else>
						<i class="el-icon-plus avatar-uploader-icon"></i>
						<p class="uploadTip">点击上传</p>
					</div>
					<div slot="tip" class="el-upload__tip" style="margin-top: -7px">承诺函扫描图片后上传，支持jpg、png格式；图片大小不超过5M。<el-button type="text" class="downTemplate" @click="download">下载承诺函模板</el-button>
</div>
				</el-upload>
				<div class="demoLetter">
					<div class="img-box">
						<img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-h5-renshe/home/knowDemo.png" alt="">
						<div class="viewLarge" @click="viewDemo">
							查看大图
						</div>
					</div>
				</div>
			</el-form-item>
			<el-form-item v-if="formData.enterType === 2 || formData.enterType === 7" label="其他辅助证明材料：" prop="materialRarList">
				<el-upload
					v-loading="baseImageUploadLoading"
					class="avatar-uploader"
					:action="action"
					:headers="headers"
					accept="image/jpg, image/jpeg, image/png"
					list-type="picture-card"
					:limit="limitMater"
					:on-exceed="handleExceed2"
					:on-remove="handleRemoveMateri"
					:on-success="baseImageSuccessMateri"
					:before-upload="beforeBaseImageUpload"
					:on-error="
						() => {
							baseImageUploadLoading = false
						}
					"
				>
					<div>
						<i class="el-icon-plus avatar-uploader-icon"></i>
						<p class="uploadTip">上传图片</p>
					</div>
					<div slot="tip" class="el-upload__tip">其他辅助证明材料扫描图片，支持jpg、png格式；最多可上传10个文件。单个图片大小不超过5M。</div>
				</el-upload>
				<div class="zip-box  zip-box-mix">
					<div class="zip-box-line">
						<div @click.stop="openInputMix('materialRarList')">
							<upload-zip-mix  
								:uploadType="'materialRarList'"
								ref="materialRarList"
								:maxSize="1024*1024*50"
								:accept="'.zip,.rar'"
								@file-obj="getFileObjMix"
								:clearType="'materialRarList'"
								:disabledUpload="fileListFinished.length>9 || fileListZip.length>9"
								@on-upload-cancel-file="onUploadCanceFileMix"
								@openInput="openInputMix('materialRarList')"
								@fileloadingdata="fileloadingdataMix">
							</upload-zip-mix>
						</div>
					</div>
					<p class="tip">其他辅助证明材料扫描图片，支持zip、rar格式；最多可上传10个文件。单个压缩文件大小不超过50M。</p>
				</div>
				<div class="upload-view"> 
					<ul class="content-accessory" v-if="previewList.materialRarList.fileListFinished.length>0">
						<li v-for="(item,index) in previewList.materialRarList.fileListFinished" :key="item.id ||index+'key'">
							<div class="accessory-box">
								<div class="accessory-top">
									<div class="top-icon">
										<svg-icon icon-class="zip"></svg-icon>
									</div>
									<p class="top-text">{{item.name}}</p>
								</div>
								<div class='accessory-bottom' >
									<el-button type="button" @click="handleDelResourceMix(item,index,'materialRarList')">删除</el-button>
								</div>
							</div>
						</li>
					</ul>
					<ul class="content-accessory" v-if="previewList.materialRarList.fileListZip.length>0">
						<li v-for="(item,index) in previewList.materialRarList.fileListZip" :key="item.id ||index+'key'">
							<div class="accessory-box">
								<div class="accessory-top">
									<div class="top-icon">
										<svg-icon  icon-class="zip"></svg-icon>
									</div>
									<p class="top-text">{{item.name}}</p>
								</div>
								<div class="accessory-bottom" v-if="item.percent<100">
									<el-button type="button" @click="delResourceMix(item, index, 'materialRarList')">取消上传</el-button>
								</div>
								<el-progress :percentage="Number(item.percent)" :stroke-width="2" :show-text="false" :color="'#1972FF'" v-if="item.percent<100"></el-progress>
							</div>
						</li>
					</ul>
				</div>
			</el-form-item>
		</el-form>
				<div class="footer-settled">
			<el-button @click="cancel" class="cancelBtn" size="small">取 消</el-button>
			<el-button type="primary" size="small" class="submitBtn" @click="submit">提交申请</el-button>
			<div class="agree">
				<el-checkbox v-model="hasreaded"></el-checkbox>
				<span class="right">
					<span class="tip" @click="hasreaded = !hasreaded">我同意并遵守</span>
					<span class="protocol" @click="dialogShow = true">《机构入驻合作协议》</span>
					<span v-if="formData.enterType === 2 || formData.enterType === 7" class="protocol" @click="dialogShow1 = true"
						>《线下实训资源提供机构承诺书》</span
					>
					<span class="tip">并保证符合承诺书内容</span>
				</span>
			</div>
		</div>
		</div>
		<!-- 班主任信息 -->
		<el-dialog
			custom-class="addTeacher"
			:title="isEdit ? '编辑班主任信息' : '添加班主任信息'"
			:visible.sync="showTeacher"
			width="640px"
			top="10vh"
			:close-on-click-modal="false"
			:modal-append-to-body="false"
			:destroy-on-close="true"
			@closed="handleCloseTeacher"
		>
			<el-form ref="teacherForm" :label-position="'left'" :rules="teacherRules" label-width="120px" :model="teacherData">
				<el-form-item label="姓名：" prop="name">
					<el-input v-model="teacherData.name" size="small" :maxlength="15" placeholder="请输入姓名"></el-input>
				</el-form-item>
				<el-form-item label="联系方式：" prop="phone">
					<el-input v-model.trim="teacherData.phone" size="small" placeholder="请输入联系方式"></el-input>
				</el-form-item>
				<el-form-item label="资格证明：" prop="certificateImgs">
					<el-upload
						v-loading="certificateLoading"
						class="avatar-uploader"
						:action="action"
						:headers="headers"
						accept="image/jpg, image/jpeg, image/png"
						list-type="picture-card"
						:file-list="fileList"
						:limit="5"
						:on-remove="handleRemoveCertificate"
						:on-success="certificateSuccess"
						:before-upload="beforeCertificateUpload"
						:on-exceed="handleExceed2"
						:on-error="
							() => {
								certificateLoading = false
							}
						"
					>
						<div>
							<i class="el-icon-plus avatar-uploader-icon"></i>
							<p class="uploadTip">点击上传</p>
						</div>
						<div slot="tip" class="el-upload__tip">支持jpg、png格式，图片大小不超过5M；最多上传5张。</div>
					</el-upload>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="showTeacher = false" class="cancelBtn" size="small">取消</el-button>
				<el-button type="primary" size="small" class="submitBtn" @click="confirmAdd">确定</el-button>
			</span>
		</el-dialog>
		<el-dialog custom-class="preview" width="900px" center title="承诺函示例" :close-on-click-modal="false" :visible.sync="previewImg">
			<div class="imgBox">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-h5-renshe/home/knowDemo.png" class="previewImg" alt />
            </div>
		</el-dialog>
		<InstitutionInProtocol :dialogShow="dialogShow" @close="handleCloseProtocol" />
		<OfflineTrainingProtocol :dialogShow1="dialogShow1" @close="handleCloseProtocol" />
	</div>
</template>
<script>
import InstitutionInProtocol from './institutionInProtocol'
import OfflineTrainingProtocol from './offlineTrainingProtocol'
import config from '@/config/url'
import { organCodeValidator,isEmail } from '@/utils/validator'
import { exportMethod } from "@/utils/downDoc";
import URL from "@/config/url";
import UploadZip from "./upload-zip";
import UploadZipMix from "./upload-zip-mix";

export default {
	name: 'institutionsInDialog',
	components: {
		OfflineTrainingProtocol,
		InstitutionInProtocol,
		UploadZip,
		UploadZipMix
	},
	computed: {
		cityList() {
			if (this.formData.province) {
				let findItem = this.provinceList.find((item) => item.code === this.formData.province)
				return findItem && findItem.children
			} else {
				return []
			}
		},
		districtList() {
			if (this.formData.city) {
				let findItem = this.cityList.find((item) => item.code === this.formData.city)
				return findItem && findItem.children
			} else {
				return []
			}
		},
	},
	watch:{
		'formData.deviceRarList':{
			handler(){
				this.limitDevice = 10 - this.formData.deviceRarList.length
			},
			immediate:true,
			deep:true,
		},
		'formData.materialRarList':{
			handler(){
				this.limitMater = 10 - this.formData.materialRarList.length
				console.log(this.limitMater);
			},
			immediate:true,
			deep:true,
		}
	},
	data() {
		const accountValidator = (rule, value, callback) => {
			const isPhone = /^1(?:3[0-9]|4[5-9]|5[0-9]|6[12456]|7[0-8]|8[0-9]|9[0-9])[0-9]{8}$/
			if (!isPhone.test(value)) {
				callback(new Error('请输入正确的手机号'))
			} else {
				callback()
			}
		}
		const checkEmail = (rule, value, callback) => {
            if (!isEmail.test(value) ) {
                callback(new Error('请输入正确的邮箱账号'))
            } else {
                callback()
            }
        }
		return {
			limitMater:10,
			limitDevice:10,
			tipShow:true,
			previewImg:false,
			LetterImageUploadLoading:false,
			isEdit: false,
			showTeacher: false,
			hasreaded: false,
			dialogShow: false,
			dialogShow1: false,
			licenseImageUploadLoading: false,
			letterImageUploadLoading:false,
			baseImageUploadLoading: false,
			certificateLoading: false,
			action: config.API + '/resource/v1/resource/uploadPicture',
			headers: {
				token: localStorage.getItem('token'),
			},
			fileList: [],
			authCode: '',
			emailAuthCode:'',
			provinceList: [],
			province: '',
			city: '',
			district: '',
			uploadFileStatus:false,
			formData: {
				name: '',
				address: '',
				phone: '',
				province: '',
				city: '',
				district: '',
				trainTeacherImage: [],
				deviceRarList:[],
				deviceList:[],
				materialRarList:[],
				materialList:[],
				letterList:[],
				urlList:[],//师资备案
				businessLicenseImage: '',
				isJoinTalent: 1,
				workType: '', //工种
				enterType: 2, // 1分销渠道方 2分销商+实训基地 3教培机构 4企业 5政府及协会 6院校 7公共线下实训基地
				headTeacherVoList: [], //班主任信息
			},
			teacherData: {
				name: '',
				phone: '',
				certificateImgs: [],
			},
			trainTeacherImage: [], //培训教师资格
			rules: {
				name: { required: true, message: '请输入机构名称', trigger: 'blur' },
				userName: { required: true, message: '请输入联系人姓名', trigger: 'blur' },
				province: { required: true, message: '请选择省', trigger: 'change' },
				city: { required: true, message: '请选择市', trigger: 'change' },
				district: { required: true, message: '请选择区', trigger: 'change' },
				organCode: [
					{ required: true, message: '请输入社会统一信用代码', trigger: 'blur' },
					{ validator: organCodeValidator, trigger: 'blur' },
				],
				email: [
                    {required:true,message:'请输入邮箱',trigger:'blur'},
                    {validator: checkEmail,trigger: "blur"}
                ],
				phone: [
					{ required: true, message: '请输入手机号', trigger: 'blur' },
					{ validator: accountValidator, trigger: 'blur' },
				],
				businessLicenseImage: { required: true, message: '请上传营业执照副本', trigger: 'blur' },
				trainTeacherImage: { required: true, message: '请上传培训教师资格证明', trigger: 'blur' },
				urlList: { required: true, message: '请上传师资备案', trigger: 'change' },
				deviceRarList: { required: true, message: '请上传场地设备信息', trigger: 'blur' },
				letterList: { required: true, message: '请上传承诺函', trigger: 'blur' },
				workType: [{ required: true, message: '请选择职业及工种', trigger: 'change' }],
				headTeacherVoList: [{ required: true, message: '请添加班主任信息', trigger: 'blur' }],
			},
			teacherRules: {
				name: { required: true, message: '请输入姓名', trigger: 'blur' },
				phone: [
					{ required: true, message: '请输入手机号', trigger: 'blur' },
					{ validator: accountValidator, trigger: 'blur' },
				],
				certificateImgs: { required: true, message: '请上传资格证明', trigger: 'blur' },
			},
			isFirstGetAuthcode: true,
			isFirstGetAuthcodeEmail:true,
			CountdownEmail:0,
			Countdown: 0,
			options: [],
			previewList:{
				deviceRarList:{
					fileListFinished:[],
					fileListZip:[],
					uploadFileStatus:false,
				},
				materialRarList:{
					fileListFinished:[],
					fileListZip:[],
					uploadFileStatus:false,
				},
			},
			fileListFinished:[],
			fileListZip:[],
		}
	},
	mounted() {
		this.getRegionList()
		// 获取职业及工种
		this.getData()
	},
	methods: {
		closeTip(){
			this.tipShow = false;
		},
		filterList(data){
			let  arr =  [];
			if(data&&data.length){
				arr = data.filter(item=>{
					if(!['营养指导员','整理收纳师','家务服务员','家庭照护员'].includes(item.vocationName)){
						return item
					}
				})
			}
			return arr
		},
		delResourceMix(item, index,type) {
            const indexs = this.previewList[type].fileListZip.findIndex(it => it.ri === item.ri);
            this.formData[type].splice(indexs, 1);
            this.$refs[type].abortUpload(item,index);
		},
		handleDelResourceMix(item,index,type) {
			if (!this.previewList[type].fileListZip.length) {
				this.formData[type].splice(index, 1);
				this.previewList[type].fileListFinished.splice(index, 1);
			} else {
				this.$message.warning('请稍候，资源正在上传');
			}
		},
		openInputMix(type) {
			let temp = '';
			if(type=='deviceRarList'){
				temp = 'deviceList'
			}else if(type=='materialRarList'){
				temp ='materialList'
			}
			if(!this.previewList[type].uploadFileStatus){
				if((this.formData[type].length + this.formData[temp]?.length) > 9){
					this.$message.error('文件个数不能超过10个');
				}else{
					this.$refs[type].handleClick();
				}
			}else{
				this.$message.warning('文件正在上传中，请稍后操作');
			}
		},
		fileloadingdataMix(params) {
			const data = params.data;
			const type = params.type;
            console.log("data", data);
            // 找到已经在上传的文件索引，有就替换，没有就新增
            const sameIndex = this.previewList[type].fileListZip.findIndex(
            item => item.index === data.index
            );
            if (sameIndex !== -1) {
            if (data.isDelete) {
                this.previewList[type].fileListZip.splice(sameIndex, 1);
            } else {
                this.previewList[type].fileListZip.splice(sameIndex, 1, data);
            }
            } else {
            this.previewList[type].fileListZip.push(data);
            }
    
            this.previewList[type].fileListZip.forEach(item => {
				if (item.index === data.index) {
					this.$set(item, "percent", data.percent);
				}
				const status = item.percent < 100;
				this.$set(item, "uploadStatus", status);
            });
            const isAllFinished = this.previewList[type].fileListZip.every(item => item.percent === 100);
            if (isAllFinished) {
				this.afterUploadMix(type);
				this.previewList[type].uploadFileFlag = false;
				this.previewList[type].uploadFileStatus = false;
			} else {
				this.previewList[type].uploadFileStatus = true;
				this.previewList[type].uploadFileFlag = true;
            }
        },
		afterUploadMix(type){
			this.previewList[type].fileListFinished = this.previewList[type].fileListFinished.concat(this.previewList[type].fileListZip);
			this.previewList[type].fileListZip = [];
		},
		getFileObjMix(params) {
            this.formData[params.type].push({...params.data});
			this.$refs.myForm.clearValidate(params.type)
        },
		onUploadCanceFileMix(params){
            if (this.previewList[params.type].fileListZip.length > 0) {
                this.previewList[params.type].uploadFileStatus = true;
            } else {
                this.previewList[params.type].uploadFileStatus = false;
            }
        },
		delResource(item, index) {
            const indexs = this.fileListZip.findIndex(it => it.ri === item.ri);
            this.formData.urlList.splice(indexs, 1);
            this.$refs.uploadFileInput.abortUpload(item,index);
		},
		handleDelResource(item,index) {
			if (!this.fileListZip.length) {
				this.formData.urlList.splice(index, 1);
				this.fileListFinished.splice(index, 1);
			} else {
				this.$message.warning('请稍候，资源正在上传');
			}
		},
		openInput() {
			if(this.fileListFinished.length>0 || this.fileListZip.length>0){
				return
			}
			if(!this.uploadFileStatus){
				if(this.formData.urlList.length > 1){
					this.$message.error('文件个数不能超过1个');
				}else{
					this.$refs.uploadFileInput.handleClick();
				}
			}else{
				this.$message.warning('文件正在上传中，请稍后操作');
			}
		},
		
		fileloadingdata(data) {
            console.log("data", data);
            // 找到已经在上传的文件索引，有就替换，没有就新增
            const sameIndex = this.fileListZip.findIndex(
            item => item.index === data.index
            );
            if (sameIndex !== -1) {
            if (data.isDelete) {
                this.fileListZip.splice(sameIndex, 1);
            } else {
                this.fileListZip.splice(sameIndex, 1, data);
            }
            } else {
            this.fileListZip.push(data);
            }
    
            this.fileListZip.forEach(item => {
				if (item.index === data.index) {
					this.$set(item, "percent", data.percent);
				}
				const status = item.percent < 100;
				this.$set(item, "uploadStatus", status);
            });
            console.log('this.fileListZip',this.fileListZip);
            const isAllFinished = this.fileListZip.every(item => item.percent === 100);
            if (isAllFinished) {
				this.afterUpload(3);
				this.uploadFileFlag = false;
				this.uploadFileStatus = false;
			} else {
				this.uploadFileStatus = true;
				this.uploadFileFlag = true;
            }
        },
		afterUpload(){
			this.fileListFinished = this.fileListFinished.concat(this.fileListZip);
			this.fileListZip = [];
		},
		getFileObj(data) {
            this.formData.urlList.push({...data});
			this.$refs.myForm.clearValidate('urlList')
        },
		onUploadCanceFile(){
            if (this.fileListZip.length > 0) {
                this.uploadFileStatus = true;
            } else {
                this.uploadFileStatus = false;
            }
        },
		download(){
			let myObj = {
				method: "get",
				url: `${URL.API}/saas/v1/branchApplyEnclosure/download`,
			};
			exportMethod(myObj,true);
		},
		downloadTeacher(){
			let myObj = {
				method: "get",
				url: `${URL.API}/saas/v1/branchApplyEnclosure/downloadTeacher`,
			};
			exportMethod(myObj,true);
		},
		viewDemo(){
			this.previewImg = true;
		},
		getData() {
			// 获取职业列表
			this.$api.rensheUsercenter.getAllLevelListNew().then((res) => {
				if (res.success && res.data) {
					// console.log('resdata-----------------',res.data)
					this.options = res.data.slice(0,res.data.length - 1);
				}
			})
		},
		getRegionList() {
			this.$api.usercenter.getTree({ type: 1 }).then((res) => {
				if (res.success && res.data) {
					let filterArr = res.data.filter((item) => {
						return item.code.length === 2 && item.children
					})
					let uniqueArr = Array.from(new Set(filterArr))
					this.provinceList = uniqueArr
				}
			})
		},
		handleAdd() {
			if (this.formData.headTeacherVoList && this.formData.headTeacherVoList.length === 20) {
				this.$message.error('最多添加20个班主任信息！')
				return
			}
			this.isEdit = false
			this.showTeacher = true
		},
		handleEditTeacher(item) {
			this.isEdit = true
			this.teacherData = { ...item }
			this.fileList = item.certificateImgs.map((i) => {
				return {
					url: i,
				}
			})
			this.$nextTick(() => {
				this.showTeacher = true
			})
		},
		handleRemoveTeacher(item) {
			let index = this.formData.headTeacherVoList.findIndex((i) => {
				return i.uniq === item.uniq
			})
			this.formData.headTeacherVoList.splice(index, 1)
		},
		async confirmAdd() {
			try {
				await this.$refs.teacherForm.validate()
			} catch (error) {
				return
			}
			let index = this.formData.headTeacherVoList.findIndex((item) => {
				return item.uniq === this.teacherData.uniq
			})

			if (index !== -1) {
				this.formData.headTeacherVoList.splice(index, 1, { ...this.teacherData })
			} else {
				this.teacherData.uniq = this.teacherData.name + this.teacherData.phone
				this.formData.headTeacherVoList.push({ ...this.teacherData })
				this.$refs.myForm.clearValidate('headTeacherVoList')
			}
			this.showTeacher = false
		},
		getAuthCode() {
			this.$refs.myForm.validateField('phone', (error) => {
				if (!error) {
					const data = { account: this.formData.phone, type: 2 }
					if (this.Countdown > 0) return
					this.Countdown = 60
					this.setCountdown()
					this.$api.usercenter.getAuthCodePublic({ data })
					this.isFirstGetAuthcode = false
				}
			})
		},
		setCountdown() {
			this.CountdownInterval = setInterval(() => {
				this.Countdown--
				if (this.Countdown <= 0) {
					clearInterval(this.CountdownInterval)
				}
			}, 1000)
		},
		setCountdownEmail() {
            this.CountdownIntervalEmail = setInterval(() => {
                this.CountdownEmail--;
                if (this.CountdownEmail <= 0) {
                    clearInterval(this.CountdownIntervalEmail);
                }
            }, 1000);
        },
        getAuthCodeEamil(){
            this.$refs.myForm.validateField('email',(error) => {
                if(!error){
                    const data = { account: this.formData.email,type:2 }
                    if (this.CountdownEmail > 0) return
                    this.CountdownEmail = 60
                    this.setCountdownEmail()
                    this.$api.usercenter.getAuthCodePublic({ data })
                    this.isFirstGetAuthcodeEmail = false
                }
            })
        },
		beforeLicenseImageUpload(file) {
			if (!['image/jpeg', 'image/jpg', 'image/png'].includes(file.type)) {
				this.$message.error('请上传jpg、png格式的图片')
				return false
			}
			if (file.size / 1024 / 1024 > 5) {
				this.$message.error('图片大小不能超过5M')
				return false
			}
			this.licenseImageUploadLoading = true
		},
		beforeLetterImageUpload(file) {
			if (!['image/jpeg', 'image/jpg', 'image/png'].includes(file.type)) {
				this.$message.error('请上传jpg、png格式的图片')
				return false
			}
			if (file.size / 1024 / 1024 > 5) {
				this.$message.error('图片大小不能超过5M')
				return false
			}
			this.letterImageUploadLoading = true
		},
		licenseImageSuccess(res) {
			this.formData.businessLicenseImage = res.data
			this.licenseImageUploadLoading = false
			this.$refs.myForm.clearValidate('businessLicenseImage')
		},
		letterImageSuccess(res) {
			this.$set(this.formData.letterList,0,{
				url:res.data,
				name:''
			})
			this.letterImageUploadLoading = false
			this.$refs.myForm.clearValidate('letterList')
		},
		beforeBaseImageUpload(file) {
			if (!['image/jpeg', 'image/jpg', 'image/png'].includes(file.type)) {
				this.$message.error('请上传jpg、png格式的图片')
				return false
			}
			if (file.size / 1024 / 1024 > 5) {
				this.$message.error('图片大小不能超过5M')
				return false
			}
			this.baseImageUploadLoading = true
		},
		baseImageSuccess(res) {
			this.formData.trainTeacherImage.push(res.data)
			// console.log('this.formData.trainTeacherImage', this.formData.trainTeacherImage)
			this.baseImageUploadLoading = false
			this.$refs.myForm.clearValidate('trainTeacherImage')
		},
		baseImageSuccessDevice(res) {
			this.formData.deviceList.push({
                url:res.data,
                name:''
            })
			this.baseImageUploadLoading = false;
			this.$refs.myForm.clearValidate('deviceRarList')
		},
		baseImageSuccessMateri(res) {
			this.formData.materialList.push({
                url:res.data,
                name:''
            })
			this.baseImageUploadLoading = false
			this.$refs.myForm.clearValidate('materialRarList')
		},
		handleRemove(file) {
			// console.log(file, fileList, '-----------------')
			let url = file.response.data
			let index = this.formData.trainTeacherImage.findIndex((item) => item === url)
			this.formData.trainTeacherImage.splice(index, 1)
			// console.log('this.formData.trainTeacherImage', this.formData.trainTeacherImage)
		},
		handleRemoveDevice(file) {
			let url = file.response.data
			let index = this.formData.deviceList.findIndex((item) => item === url)
			this.formData.deviceList.splice(index, 1)
		},
		handleRemoveMateri(file) {
			let url = file.response.data
			let index = this.formData.materialList.findIndex((item) => item === url)
			this.formData.materialList.splice(index, 1)
		},
		handleExceed() {
			this.$message.error('最多上传10张图片')
		},
		beforeCertificateUpload(file) {
			if (!['image/jpeg', 'image/jpg', 'image/png'].includes(file.type)) {
				this.$message.error('请上传jpg、png格式的图片')
				return false
			}
			if (file.size / 1024 / 1024 > 5) {
				this.$message.error('图片大小不能超过5M')
				return false
			}
			this.certificateLoading = true
		},
		certificateSuccess(res) {
			this.teacherData.certificateImgs.push(res.data)
			// console.log('this.teacherData.certificateImgs', this.teacherData.certificateImgs)
			this.certificateLoading = false
			this.$refs.teacherForm.clearValidate('certificateImgs')
		},
		handleRemoveCertificate(file) {
			// console.log(file, fileList, '-----------------')
			let url = ''
			if (!file.response) {
				url = file.url
			} else {
				url = file.response.data
			}
			let index = this.teacherData.certificateImgs.findIndex((item) => item === url)

			this.teacherData.certificateImgs.splice(index, 1)
			// console.log('this.teacherData.certificateImgs', this.teacherData.certificateImgs)
		},
		handleExceed2() {
			if((this.formData.deviceRarList.length + this.formData.deviceList.length) >9){
				this.$message.error('场地设备信息最多上传10个文件')
			}else if((this.formData.materialRarList.length + this.formData.materialList.length) >9){
				this.$message.error('其他辅助证明资料最多上传10个文件')
			}
		},
		handleProvinceChange() {
			this.formData.city = ''
			this.formData.district = ''
			this.$nextTick(() => {
				this.$refs.myForm.clearValidate(['city', 'district'])
			})
		},
		handleCityChange() {
			this.formData.district = ''
			this.$nextTick(() => {
				this.$refs.myForm.clearValidate('district')
			})
		},
		cancel() {
			this.$router.go(-1);
		},
		async submit() {
			const that = this;
			if(this.previewList.deviceRarList.uploadFileStatus || this.previewList.materialRarList.uploadFileStatus){
				this.$message.warning('文件正在上传中，请稍后操作');
				return 
			}
			try {
				if(this.formData.deviceList && this.formData.deviceList.length){
					this.$refs.myForm.validateField(['name','userName','province','city','district','organCode','phone','businessLicenseImage','headTeacherVoList','workType','letterList','urlList','trainTeacherImage'])
				}else{
					await this.$refs.myForm.validate()
				}
			} catch (error) {
				return
			}
			if (!this.hasreaded) {
				if (this.formData.enterType === 2 || this.formData.enterType === 7) {
					this.$message.warning('请勾选机构入驻合作协议、线下实训资源提供机构承诺书')
				} else if (this.formData.enterType === 1) {
					this.$message.warning('请勾选机构入驻合作协议')
				}
				return
			}

			let data = { ...this.formData }
			data.regionCode = data.district
			data.authCode = this.authCode
			data.emailAuthCode = this.emailAuthCode
			if (data.enterType === 2 || data.enterType === 7) {
				//线下实训基地
				data.workType = data.workType.join(',')
			} else {
				data.workType = ''
			}
            if(data.enterType === 7){
                data.isJoinTalent = 0
            }

			this.$api.rensheUsercenter.saveJoinTrain(data).then((res) => {
				if (res.success) {
					this.$alert('审核结果将以短信通知给您，请注意查收', '已提交', {
						confirmButtonText: '确定',
						center: true,
						customClass: 'messageBox',
						confirmButtonClass: 'dialog_confirmBtn',
						callback: () => {
							that.$router.go(-1);
						},
					})
				}
			})
		},
		handleCloseTeacher() {
			this.teacherData = {
				name: '',
				phone: '',
				certificateImgs: [],
				uniq: '',
			}
			this.fileList = []
			this.showTeacher = false
			this.$nextTick(() => {
				this.$refs.teacherForm.clearValidate()
			})
		},
		handleCloseProtocol() {
			this.dialogShow = false
			this.dialogShow1 = false
		},
	},
}
</script>

<style lang="stylus" scoped>
@import '../asset/css/institutionsInDialog.styl'
@import '../asset/css/upload.styl'
.submit_info .el-form-item
	margin-bottom:16px;
	/deep/.el-form-item__label
		line-height:32px;

.agree
	padding-left:40px;
	width:1200px;
	margin:8px auto 0;
	.right
		display:inline-block
		text-align left
		margin-left 5px
		.tip
			color: #999999
			cursor:pointer
.previewImg {
	max-height:460px;
}
.zip-box-mix
	margin-top:12px;
	p
		margin-top:4px;
.zip-box
	.zip-box-line
		display:flex;
	p
		line-height: 24px;
		font-size: 14px;
		color: #999;
		word-break: normal;
.preview
	.imgBox
		text-align:center;
.letterBox
	/deep/.el-form-item__content
		position:relative;
		.demoLetter
			position:absolute;
			left:135px;
			top:0;
			display flex;
			flex-direction:column;
			margin-left:10px;
			align-items: center;
			.img-box
				position:relative;
				&:hover
					cursor pointer
					.viewLarge
						display:block;
			p
				line-height:24px;
			.el-button
				padding:0;
				text-align:left;
				margin-top:3px;
				span
					line-height:24px;
			img
				width:122px;
				height:122px;
				border:1px dashed #ccc;
				border-radius:4px;
				box-sizing:border-box;
				vertical-align: middle;
			.viewLarge
				position:absolute;
				left:0;
				right:0;
				top:0;
				width:122px;
				height:122px;
				background:rgba(0,0,0,.7);
				color:#fff;
				border-radius:4px;
				line-height:124px;
				text-align:center;
				display:none;
.wd-settled
	background: #F8FAFC;
	padding-bottom: 24px
	box-sizing: border-box
	.settled-form>.el-form
		width: 712px
		margin: 0 auto
.settled-header
	height:260px;
	background:url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc-renshe/settled-branch.png') no-repeat center center ;
	background-size:cover;
.settled-form
	width:1200px;
	margin:-60px auto 0;
	background:#fff;
	border-radius:8px;
	padding-top:28px;
	padding-bottom:32px;
.footer-settled
	margin-top:48px;
	text-align:center;
	.el-button
		width:120px;
.submit_info /deep/.el-form-item__label{
	padding-right:0;	
}
.tipTxt{
    color:#804515;
    font-size:14px;
    height:32px;
    line-height:32px;
	padding:0 12px;
    background:#FFF1E5;
    border:1px solid #FF7801;
    border-radius:4px;
    width:712px;
    margin:0 auto 24px 0;
    display: flex;
    align-items: center;
    box-sizing: border-box;
	position:relative;
    .icon{
        font-size: 14px;
        margin-right:8px;
    }
}
.tipTxt .closeTip{
	position:absolute;
	top:50%;
	transform:translateY(-50%);
	content:'';
	right:12px;
	font-size:10px;
	z-index:88;
	line-height:10px;
	.icon{
		font-size:10px;
		margin-right:0;
	}
	&:hover{
		cursor:pointer
	}
}
.tipMix
	line-height: 24px;
	color: #999;
.downTemplate
	padding:0;
.selectItem
	.el-select
		width:100%;
.tipBox-teacher
	padding-top:12px;
	margin-left:8px;
	.el-button
		padding:0;
</style>
