<!-- 
 * @description: 文档上传
 * @fileName: upload-doc.vue 
 * @author:ninghf 
 * @date: 2021-08-05 17:04:34
!-->
<template>
    <div class="upload-handle">
        <input id="file" type="file" class="hp-uploadBtn1" ref="file"  :accept="accept" style="visibility: hidden;width:90px;margin-top:-20px;"
            @change="handleClickUpload($event)"
        />
		<i class="el-icon-plus avatar-uploader-icon"></i>
        <button type="primary" plain class="upload-tips-answer" 
			:disabled="disabledUpload"
		>上传压缩包</button>
    </div>
       
</template>
<script>
	export default {
        name: 'zuoyeUploadDoc',
		props:{
			disabledUpload:{
				type:Boolean,
				default:false
			},
			maxSize: {
				type: Number,
				required: true,
			},
			accept: {
				type: String,
				default: ''
			},
			reg: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				partSize : 1024 * 1024, // 每个分片大小(byte)
				parallel : 3, // 同时上传的分片数
				credentials :'',// STS凭证
				ossClient:null, // oss客户端实例
				counts:0,
				datas :[],
				onLine: navigator.onLine,
				list: [],
				selectFileList: [],
				bucketName:'',
            }
		},
		methods: {
			handleClick() {
				this.$refs.file.click();
			},
			// zip
			async initOSSClientZip(datas) {
				const OSS = require('ali-oss');
				this.bucketName = datas.data.scormBucket
				this.ossClient = new OSS({
					region: datas.data.region,
					accessKeyId: datas.data.accessKeyId,
					accessKeySecret: datas.data.accessKeySecret,
					bucket: this.bucketName, /* 装图片的桶名 */
					stsToken: datas.data.securityToken,
					endpoint: datas.data.endpoint, // 新增加
				});
			},
			// 上传客户见证
			async handleClickUpload(e, files) {
				const api = 'getAliyunDocConf'
				this.datas = await this.$api.rensheUsercenter[api]();
				let filesList = files || e.target.files;
				// let suffixName = ''
				// 拿到出去后缀的名字
				// var suffixCount =  filesList[0].name.split('.').length
				// suffixName = filesList[0].name.split('.')[suffixCount-1]
				// 如果后缀名为zip，则使用scrom格式的上传方式
				// if(suffixName == 'zip'){
					await this.initOSSClientZip(this.datas)
				// }else{
				// 	await this.initOSSClient(this.datas)
				// }
				let reg = /\.(rar|zip)$/i
				for (let i = 0; i < filesList.length; i += 1) {
					if(!reg.test(filesList[i].name)) {
						this.$message.warning(`暂不支持此格式上传`);
						return;
					}
					// let afterNamess = ''
					// 拿到出去后缀的名字
					// var countss =  filesList[i].name.split('.').length
					// afterNamess = filesList[i].name.split('.')[countss-1]
					if (filesList[i].size > this.maxSize) {
						// if(afterNamess == 'zip'){
							this.$message.warning(this.$t('RAAS_WEB_THE_SIZE_OF_THE_COMPRESSED_PACKAGE_MUST',{text:'压缩包大小不得超过'})+'50M');
						// }else{
						// 	this.$message.warning(`文档附件大小不得超过50M`);
						// }
						return;
					}
				}
				this.counts = filesList.length
				if (this.counts>1) {
					this.$message.error('文件个数不能超过1个');
				} else {
					this.selectFileList = []
					for (let i = 0; i < this.counts; i++) {
						const fileInfo = {
							file: filesList[i],
							name: filesList[i].name,
							index: i,
							size: filesList[i].size
						}
						localStorage.removeItem('ossCheckpoints-' + i)
						this.selectFileList.push(fileInfo)
					}
					this.uploadFileList(0)
				}
			},
			uploadFileList(index) {
				//检查要上传的文件是否存在
                const file = this.selectFileList[index]
				if (!file) return
				this.$set(file, 'path', this.creatFileUrl(file.name))
				this.handleUpload(file).then(res=>{
					// 判断是否为中止上传，isCancel取消正在上传
					if (!res.isCancel) {
						if (res.index <= this.counts) {
							this.uploadFileList(res.index+1)
						} else {
							console.log('upload list finished')
						}
					} else {
						localStorage.removeItem('ossCheckpoints-' + index)
						const deleteObj = Object.assign({isDelete: true}, this.selectFileList[index])
						this.$emit('fileloadingdata', deleteObj)
						this.selectFileList.splice(index, 1)
						if (this.selectFileList.length> 0) {
							this.selectFileList.forEach((it, i)=>{
								it.index = i
							})

							this.uploadFileList(index)
						} else {
                            const fileRef = this.$refs.file
                            fileRef.value = '';
                        }
					}
				}).catch(e =>{
					this.$message.error('上传出错了，请刷新页面重试')
					console.log(e)
				})
			},
			handleUpload(obj){
				console.log('obj',obj);
				let beforeNames = ''
				let afterNames = ''
				console.log(beforeNames);
				console.log(afterNames);
				// 拿到出去后缀的名字
				var counts =  obj.name.split('.').length
				obj.name.split('.').forEach((ite,ind) => {
						if(ind <= counts - 2){
							if(ind == counts - 2){
								beforeNames += ite
							}else{
								beforeNames += ite + '.'
							}
						}
					})
				afterNames = obj.name.split('.')[counts-1]
				const fileType = 'to-convert-doc'
				const localCpt = localStorage.getItem('ossCheckpoints-' + obj.index);
				const checkpoint = JSON.parse(localCpt)
				let uploadUrl = ''
				// if(afterNames == 'zip'){
					// uploadUrl = `${this.datas.data.scormSource}/${obj.path}`;
				// }else{
					uploadUrl = `${this.datas.data.filePathPrefix}${fileType}/${obj.path}`;
				// }
				return new Promise((resolve, reject)=>{
					this.ossClient.multipartUpload(uploadUrl, obj.file, {
						checkpoint: checkpoint,
						progress: (percentage, checkpoint) => this.handleProgess(percentage, checkpoint, obj),
					}).then(result => {
						const resultClone = result;
						let resultArr = resultClone.res.requestUrls[0].split('?')[0];
						console.debug(resultArr,'resultArr');
						const temp = {
							name: obj.name,
							// name:beforeNames.toLocaleLowerCase() + '.' + afterNames.toLocaleLowerCase(),
							size:  obj.size,
							url: obj.path,
							bucketName: this.bucketName,
                        }
						this.$emit('file-obj', temp)
						const resObj = Object.assign({}, temp, {index: obj.index})
						resolve(resObj)
						this.$refs.file.value = null
					}).catch(err => {
						if (err.name==='cancel') {
							const resObj = Object.assign({isCancel: true}, obj, err)
							resolve(resObj)
						} else {
							reject(err)
						}
					});
				})
			},
			handleProgess(percentage, checkpoint, obj) {
				const cpt = JSON.stringify(checkpoint)
				if (percentage!==1) {
					localStorage.setItem('ossCheckpoints-' + obj.index, cpt)
				} else {
					localStorage.removeItem('ossCheckpoints-' + obj.index)
				}
                const fileloadingNum = Math.ceil(percentage * 100);
				let beforeName = ''
                let afterName = ''
				// 拿到出去后缀的名字
				var count =  obj.name.split('.').length
				obj.name.split('.').forEach((ite,ind) => {
						if(ind <= count - 2){
							if(ind == count - 2){
								beforeName += ite
							}else{
								beforeName += ite + '.'
							}
						}
					})
                afterName = obj.name.split('.')[count-1]
                let temp =  {
                    index: obj.index,
                    percent: fileloadingNum,
					name: obj.name,
                    ri: obj.index,
					counts: this.counts,
					beforeName:beforeName.toLocaleLowerCase(),
					afterName:afterName.toLocaleLowerCase()
				}
                if (checkpoint) {
                    temp = Object.assign(temp, {
                        uploadName: checkpoint.name,
                        uploadId: checkpoint.uploadId
                    })
                }
                this.$emit('fileloadingdata', temp)
			},
			async abortUpload(obj) {
				await this.ossClient.abortMultipartUpload(obj.uploadName, obj.uploadId);
				this.$emit('on-upload-cancel-file', obj);
			},
			creatFileUrl(name) {
				const date = new Date();
				const year = date.getFullYear();
				const month = date.getMonth() + 1;
				const timestamp = new Date().getTime();
				const fileSuffix = name.lastIndexOf('.');
				const fileExt = name.substring(fileSuffix);// 后缀名
				const storeAs = `${timestamp}${fileExt}`;
				return `${year}/${this.add0(month)}/${storeAs}`
			},
			add0(m) {
				return m < 10 ? `0${m}` : m;
			},
		}
	}
</script>
<style lang="stylus" scoped>
.upload-handle{
	width: 120px;
    height: 120px;
    background-color: #f8faff;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
	text-align:center;
	.avatar-uploader-icon{
		margin-top:0;
	}
    .upload-tips-answer{
        line-height: 22px;
		color: #95accf;
		margin-top: 10px;
		display:block;
		outline:none;
		appearance:none;
		border:none;
		background:transparent;
		text-align:center;
		width:100%;
    }
}
</style>